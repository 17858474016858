<template>
  <div class="page page__content-wrapper">
    <div class="page__full-width">
      <page-item v-for="(item, idx) in presentations" :content="item" :key="idx"></page-item>

      <div class="page__min-container">
        <ButtonDownload
          text-button="Скачать шаблон"
          url="../download-file/07-Presentation/Steor-Presentation.zip"
          class="page__download-block"
          :icons="[{url: '', icon: 'figma.svg'}, {url: '', icon: 'pdf.svg'}]"
        />
      </div>
    </div>


    <BottomNavBlock :prev-link="bottomNav.pre" :next-link="bottomNav.next"/>
  </div>
</template>

<script>
import BottomNavBlock from "@/components/Elements/BottomNavBlock/BottomNavBlock"
import ButtonDownload from "@/components/Elements/ButtonDownload/ButtonDownload"
import PageItem from "@/components/Elements/PageItem/PageItem"

export default {
  components: {
    PageItem,
    ButtonDownload,
    BottomNavBlock
  },

  data() {
    return {
      bottomNav: {
        pre: {
          url: "/brand/office-identity",
          text: "Офисная айдентика"
        },
        next: {
          url: "/brand/souvenir-products",
          text: "Сувенирная продукция"
        }
      },
      presentations: [
        {
          type: "image",
          title: "Обложка",
          images: [
            {
              img: "presentations-page/pres-01.png",
            },
            {
              img: "presentations-page/pres-02.png",
            },
            {
              img: "presentations-page/pres-03.png",
            },
            {
              img: "presentations-page/pres-04.png",
            },
            {
              img: "presentations-page/pres-05.png"
            }
          ]
        },
        {
          type: "image",
          title: "Разделители",
          images: [
            {
              img: "presentations-page/pres-06.png",
            },
            {
              img: "presentations-page/pres-07.png",
            },
            {
              img: "presentations-page/pres-08.png",
            },
            {
              img: "presentations-page/pres-09.png"
            }
          ]
        },
        {
          type: "image",
          title: "Типовые слайды",
          images: [
            {
              img: "presentations-page/pres-10.png"
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/var-style";
@import "../../../assets/style/text-style";
@import "../../../assets/style/page-block";
@import "../../../assets/style/buttons";

.page {
  margin-bottom: 192px;
  &__min-container {
    margin-bottom: 64px;
  }
}

/deep/.main-btn_download {
  padding-right: 47px;
}

@media screen and (max-width: 1540px) {
  .page__content-wrapper {
    padding-left: 64px;
  }
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 263px;
  }
}


@media screen and (max-width: 1320px) {
  .page__content-wrapper {
    padding-left: 0;
  }
}

@media screen and (max-width: 1216px) {
  .page__link-block.download-block {
    display: none;
  }
}

@media screen and (max-width: 1090px) {
  .page {
    margin-top: 0;
    padding-top: 48px;
  }
}

@media screen and (max-width: 896px) {
  .pages-wrap {
    .container {
      .page {
        &.page__content-wrapper {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }

  .page {
    margin-bottom: 192px;

    &__full-width {
      width: 100%;
    }

    &__download-block {
      display: none;
    }
  }
}

</style>
